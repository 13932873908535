import React, { useState } from 'react';
import {
    Drawer,
    DrawerProps,
    Button,
    Form,
    Stack,
    InputNumber,
    InputGroup,
    Slider,
    Rate,
    Radio,
    Input,
    RadioGroup,
    DatePicker,
    useToaster,
    Notification,
} from 'rsuite';
import auth from "../../../config/firebase";

const AddNewMember = (props) => {
    const { onClose, dataJeziki, dataPodrocja, dataVloge, ...rest } = props;
    const [responseData, setResponseData] = useState();
    const [userData, setUserData] = useState({
        ime: "",
        priimek: "",
        id_izkaznica: null,
        spol: null,
        datum_rojstva: null,
        kraj_rojstva: "",
        objava_v_iskalniku: null,
        datum_vclanitve: null,
        spletnastran: "",
        telefonmobi: "",
        telefonsluzba: "",
        telefondoma: "",
        email: "",
        ulica: "",
        postna_stevilka: "",
        posta: "",
        materni_jezik: "",
        drzavljanstvo: "",
        osnovni_poklic: "",
        solska_izobrazba: ""
    });

    const toaster = useToaster();

    const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);

    const handleSubmit = async (e) => {
		e.preventDefault();

        const messageSuccess = (
            <Notification type="success" header="Uspešno" closable>
                <p>Član_ica uspešno shranjen_a</p>
            </Notification>
        );

        const messageFailed = (
            <Notification type="error" header="Napaka" closable>
                <p>Pri shranjevanju smo naleteli na napako.</p>
            </Notification>
        );
    
        try {
			const user = auth.currentUser;
			const token = user && (await user.getIdToken());

            // console.log(testData)
	
			const response = await fetch("/express/api/v1/clan/", {
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
                    ime: userData.ime,
                    priimek: userData.priimek,
                    id_izkaznica: 123124,
                    spol: userData.spol,
                    datum_rojstva: userData.datum_rojstva.toISOString(),
                    kraj_rojstva: userData.kraj_rojstva,
                    objava_v_iskalniku: userData.objava_v_iskalniku,
                    datum_vclanitve: userData.datum_rojstva.toISOString(),
                    spletnastran: userData.spletnastran,
                    telefonmobi: userData.telefondoma,
                    telefonsluzba: userData.telefonsluzba,
                    telefondoma: userData.telefondoma,
                    email: userData.email,
                    ulica: userData.ulica,
                    postna_stevilka: userData.postna_stevilka,
                    posta: userData.posta,
                    materni_jezik: userData.materni_jezik,
                    drzavljanstvo: userData.drzavljanstvo,
                    osnovni_poklic: userData.osnovni_poklic,
                    solska_izobrazba: userData.solska_izobrazba,
                    sedanja_zaposlitev: userData.sedanja_zaposlitev,
                    nacin_pridobivanja_znanja_tujih_jezikov: userData.nacin_pridobivanja_znanja_tujih_jezikov,
                    prevajalska_praksa: userData.prevajalska_praksa
                  })
			});
		
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
		
			const jsonResponse = await response.json();
			setResponseData(jsonResponse);
			setUserData({
                ime: "",
                priimek: "",
                id_izkaznica: null,
                spol: null,
                datum_rojstva: null,
                kraj_rojstva: "",
                objava_v_iskalniku: null,
                datum_vclanitve: null,
                spletnastran: "",
                telefonmobi: "",
                telefonsluzba: "",
                telefondoma: "",
                email: "",
                ulica: "",
                postna_stevilka: "",
                posta: "",
                materni_jezik: "",
                drzavljanstvo: "",
                osnovni_poklic: "",
                solska_izobrazba: "",
                sedanja_zaposlitev: "",
                nacin_pridobivanja_znanja_tujih_jezikov: "",
                prevajalska_praksa: ""
            });
            toaster.push(messageSuccess, { placement: "topStart" })
			onClose();
		} catch (error) {
			console.error('Error making POST request:', error);
			toaster.push(messageFailed, { placement: "topStart" })
		}
	}

    return (
        <Drawer backdrop="static" size="sm" placement="right" onClose={onClose} {...rest}>
            <Drawer.Header>
                <Drawer.Title>Dodaj novega člana</Drawer.Title>
                <Drawer.Actions>
                    <Button onClick={handleSubmit} appearance="primary" color="green">
                        Shrani
                    </Button>
                    <Button onClick={onClose} appearance="subtle">
                        Prekliči
                    </Button>
                </Drawer.Actions>
            </Drawer.Header>

            <Drawer.Body>
                <Form
                    fluid
                    // defaultValue={userData}
                    formValue={userData}
                    onChange={userData => setUserData(userData)}
                >
                    <Stack justifyContent="space-between" style={{ marginBottom: 20 }}>
                        <Form.Group>
                            <Form.ControlLabel>Ime</Form.ControlLabel>
                            <Form.Control name="ime" style={{ width: 200 }} />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Priimek</Form.ControlLabel>
                            <Form.Control name="priimek" style={{ width: 200 }} />
                        </Form.Group>
                    </Stack>
                    <Stack justifyContent="space-between" style={{ marginBottom: 20 }}>
                        <Form.Group controlId="spol" style={{ width: 200 }}>
                            <Form.ControlLabel>Spol:</Form.ControlLabel>
                            <Form.Control name="spol" accepter={RadioGroup} inline>
                                <Radio value="zenski">Ženski</Radio>
                                <Radio value="moski">Moški</Radio>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Številka izkaznice</Form.ControlLabel>
                            <Form.Control accepter={InputNumber} name="id_izkaznica" style={{ width: 200 }} />
                        </Form.Group>
                    </Stack>
                    <Stack justifyContent="space-between" style={{ marginBottom: 20 }}>
                        <Form.Group>
                            <Form.ControlLabel>Datum rojstva</Form.ControlLabel>
                            <Form.Control
                                placement="auto"
                                isoWeek
                                name="datum_rojstva"
                                oneTap
                                accepter={DatePicker}
                                style={{ width: 200 }}
                                locale={{
                                    sunday: 'Ned',
                                    monday: 'Pon',
                                    tuesday: 'Tor',
                                    wednesday: 'Sre',
                                    thursday: 'Čet',
                                    friday: 'Pet',
                                    saturday: 'Sob',
                                    ok: 'OK',
                                    today: 'Danes',
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Kraj rojstva</Form.ControlLabel>
                            <Form.Control name="kraj_rojstva" style={{ width: 200 }} />
                        </Form.Group>
                    </Stack>
                    <Stack justifyContent="space-between" style={{ marginBottom: 20 }}>
                        <Form.Group>
                            <Form.ControlLabel>Datum včlanitve</Form.ControlLabel>
                            <Form.Control
                                placement="auto"
                                isoWeek
                                name="datum_vclanitve"
                                oneTap
                                accepter={DatePicker}
                                style={{ width: 200 }}
                                locale={{
                                    sunday: 'Ned',
                                    monday: 'Pon',
                                    tuesday: 'Tor',
                                    wednesday: 'Sre',
                                    thursday: 'Čet',
                                    friday: 'Pet',
                                    saturday: 'Sob',
                                    ok: 'OK',
                                    today: 'Danes',
                                }}
                            />
                        </Form.Group>
                        <Form.Group controlId="objava_v_iskalniku" style={{ width: 200 }}>
                            <Form.ControlLabel>Objava v iskalniku:</Form.ControlLabel>
                            <Form.Control name="objava_v_iskalniku" accepter={RadioGroup}>
                                <Radio value={1}>DA</Radio>
                                <Radio value={0}>NE</Radio>
                            </Form.Control>
                        </Form.Group>
                    </Stack>
                    <Stack justifyContent="space-between" style={{ marginBottom: 20 }}>
                        <Form.Group>
                            <Form.ControlLabel>Spletna stran</Form.ControlLabel>
                            <Form.Control name="spletnastran" style={{ width: 200 }} />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Telefon (mobilni)</Form.ControlLabel>
                            <Form.Control name="telefonmobi" style={{ width: 200 }} />
                        </Form.Group>
                    </Stack>
                    <Stack justifyContent="space-between" style={{ marginBottom: 20 }}>
                        <Form.Group>
                            <Form.ControlLabel>Telefon (domači)</Form.ControlLabel>
                            <Form.Control name="telefondoma" style={{ width: 200 }} />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Telefon (službeni)</Form.ControlLabel>
                            <Form.Control name="telefonsluzba" style={{ width: 200 }} />
                        </Form.Group>
                    </Stack>
                    <Stack justifyContent="space-between" style={{ marginBottom: 20 }}>
                        <Form.Group>
                            <Form.ControlLabel>E-poštni naslov</Form.ControlLabel>
                            <Form.Control name="email" type="email" style={{ width: 200 }} />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Ulica</Form.ControlLabel>
                            <Form.Control name="ulica" style={{ width: 200 }} />
                        </Form.Group>
                    </Stack>
                    <Stack justifyContent="space-between" style={{ marginBottom: 20 }}>
                        <Form.Group>
                            <Form.ControlLabel>Poštna številka</Form.ControlLabel>
                            <Form.Control name="postna_stevilka" style={{ width: 200 }} />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Pošta</Form.ControlLabel>
                            <Form.Control name="posta" style={{ width: 200 }} />
                        </Form.Group>
                    </Stack>
                    <Stack justifyContent="space-between" style={{ marginBottom: 20 }}>
                        <Form.Group>
                            <Form.ControlLabel>Državljanstvo</Form.ControlLabel>
                            <Form.Control name="drzavljanstvo" style={{ width: 200 }} />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Materni jezik</Form.ControlLabel>
                            <Form.Control name="materni_jezik" style={{ width: 200 }} />
                        </Form.Group>
                    </Stack>
                    <Stack justifyContent="space-between" style={{ marginBottom: 20 }}>
                        <Form.Group>
                            <Form.ControlLabel>Osnovni poklic</Form.ControlLabel>
                            <Form.Control name="osnovni_poklic" style={{ width: 200 }} />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Šolska izobrazba</Form.ControlLabel>
                            <Form.Control name="solska_izobrazba" style={{ width: 200 }} />
                        </Form.Group>
                    </Stack>
                    <Stack justifyContent="space-between" style={{ marginBottom: 20 }}>
                        <Form.Group>
                            <Form.ControlLabel>Sedanja zaposlitev</Form.ControlLabel>
                            <Form.Control name="sedanja_zaposlitev" style={{ width: 200 }} />
                        </Form.Group>
                    </Stack>
                    <Stack justifyContent="space-between" style={{ marginBottom: 20 }}>
                        <Form.Group>
                            <Form.ControlLabel>Način pridobivanja znanja tujih jezikov</Form.ControlLabel>
                            <Form.Control accepter={Textarea} style={{ width: 460 }} rows={2} name="nacin_pridobivanja_znanja_tujih_jezikov"/>
                        </Form.Group>
                    </Stack>
                    <Stack justifyContent="space-between" style={{ marginBottom: 20 }}>
                        <Form.Group>
                            <Form.ControlLabel>Prevajalska praksa</Form.ControlLabel>
                            <Form.Control accepter={Textarea} style={{ width: 460 }} rows={2} name="prevajalska_praksa" />
                        </Form.Group>
                    </Stack>
                </Form>
            </Drawer.Body>
        </Drawer>
    );
};

export default AddNewMember;