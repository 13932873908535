import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import auth from "../../config/firebase";
import { useAuth } from "../../contexts/AuthContext";
import Logout from "../accounts/Logout";
import { Navbar, Nav } from 'rsuite';
import { ArrowLeftOnRectangleIcon, HomeIcon, UserCircleIcon, Cog6ToothIcon } from "@heroicons/react/24/solid";

export default function Header() {
    const [modal, setModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = auth.currentUser;
                const token = user && (await user.getIdToken());
        
                const payloadHeader = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                };
                const res = await fetch("/express", payloadHeader);
                console.log(await res.text());
            } catch (e) {
                console.log(e);
            }
        };

        fetchData();
    }, []);  
    
    const { currentUser } = useAuth();
    // console.log(currentUser);
    return (
        <div style={{marginBottom: "2rem"}}>
            <Navbar appearance="inverse">
                <Link to="/" className="rs-navbar-brand">
                    <span className="">
                        Urejevalnik
                    </span>
                </Link>
                <Nav>
                    {/* <Nav.Item icon={<HomeIcon style={{height: "100%"}} />}>Seznam članov</Nav.Item> */}
                    {currentUser && ( <>
                        <Link
                            to="/"
                            className="rs-navbar-item"
                        >
                            <HomeIcon className="rs-navbar-item-icon" style={{height: "100%"}} />
                            <span>Seznam članov</span>
                        </Link>
                        <Link
                            to="/parametri"
                            className="rs-navbar-item"
                        >
                            <Cog6ToothIcon className="rs-navbar-item-icon" style={{height: "100%"}} />
                            <span>Parametri</span>
                        </Link>
                        </>)}
                    
                    {/* <Nav.Menu title="About">
                        <Nav.Item>Company</Nav.Item>
                        <Nav.Item>Team</Nav.Item>
                        <Nav.Menu title="Contact">
                        <Nav.Item>Via email</Nav.Item>
                        <Nav.Item>Via telephone</Nav.Item>
                        </Nav.Menu>
                    </Nav.Menu> */}
                </Nav>
                {currentUser && (
                    <>
                        <Nav pullRight>
                            <Nav.Item onClick={() => setModal(true)} icon={<ArrowLeftOnRectangleIcon style={{height: "100%"}} />}>
                                Odjava
                            </Nav.Item>
                            <Link
                                    to="/profile"
                                    className="rs-navbar-item"
                                >
                                    <UserCircleIcon className="rs-navbar-item-icon" style={{height: "100%"}} />
                                    <span>{currentUser.email}</span>
                                    {/* <img
                                        className=""
                                        src={currentUser.photoURL}
                                        width={"100px"}
                                        alt=""
                                    /> */}
                            </Link>
                        </Nav>
                    </>
                )}
            </Navbar>
            {modal && <Logout modal={modal} setModal={setModal} />}            
        </div>
    );
}